<template>
	<div class="container-large pb-4">
		<transition name="fade" mode="out-in">
			<div 
				v-if="step == 'pre'" 
				:key="step"
			></div>
			<div 
				v-if="step == 'module'" 
				:key="step"
				class="pb-3"
			>
				<Modules 
					:label="'Procedimentos'"
					type="strict"
					filtering_by="proceeding"
					:module_id="selected.module_id"
					@next="WalkStep"
				/>
			</div>
			<div 
				v-if="step == 'modality'"
				:key="step"
				class="pb-3"
			>
				<Modalities 
					:label="'Procedimentos'"
					type="all"
					:module_id="selected.module_id"
					:modality_id="selected.modality_id"
					@next="WalkStep"
					@previous="step = 'module'"
				/>
			</div>
			<div 
				v-if="step == 'group'"
				:key="step"
				class="pb-3"
			>
				<ProceedingGroup
					label="Procedimentos"
					:modality_id="selected.modality_id"
					:group_id="selected.group_id"
					:groups="groups"
					@update-groups="UpdateGroups"
					@next="WalkStep"
					@previous="step = 'modality'"
				/>
			</div>
			<div 
				v-if="step == 'subgroup'"
				:key="step"
				class="pb-3"
			>
				<ProceedingSubgroup
					label="Procedimentos"
					:modality_id="selected.modality_id"
					:parent_id="selected.group_id"
					:subgroup_id="selected.subgroup_id"
					:subgroups="subgroups"
					@update-groups="UpdateSubgroups"
					@next="WalkStep"
					@previous="step = 'group'"
				/>
			</div>
			<div 
				v-if="step == 'proceedings-list'"
				:key="step"
				class="pb-3"
			>
				<div class="row justify-content-between align-items-end">
					<div class="col-12">
						<button
							type="button"
							class="btn btn-light px-5"
							@click="step = 'subgroup'"
						>
							voltar
						</button>
					</div>
					<div class="col-12 d-flex align-items-center justify-content-between flex-wrap mt-4">
						<div class="col px-0 mb-3 m-md-0">
							<span>{{ modality_name }}</span>
							<h4 class="font-24 font-md-32 m-0">Procedimentos</h4>
						</div>
						<div class="col-12 col-md-6 px-0 d-flex align-items-center">
							<div class="col mr-3 px-0">
								<div class="form-group m-0 position-relative">
									<input 
										type="search" 
										class="form-control pl-5" 
										placeholder="Buscar..."
										v-model="search"
									>
									<i 
										class="far fa-search font-14 position-absolute ps-l-0 ml-3 ps-v-center"
									></i>

								</div>
								
							</div>
							<div class="col-auto px-0">
								<button
									type="button"
									class="btn btn-primary px-5"
									@click="New"
								>
									criar
								</button>
							</div>
						</div>
					</div>
				</div>
				<hr class="my-3 my-md-4">
				<DataTables
					:headers="headers"
					:fixedHeader="true"
					v-model="proceedings"
					max_height="560px"
					:search="search"
					:tr-button="true"
					@by-click-on-tr="Config"
				>
					<template v-slot:body="{ item }">
						<td scope="col"
							:class="{
								'text-primary':         item.status,
								'text-secondary':       !item.status
							}"
						>
							<i 
								class="font-40 fad"
								:class="{
									'fa-toggle-on':     item.status,
									'fa-toggle-off':    !item.status
								}"
								@click.stop="ToggleProceedingStatus(item)"
							></i>
						</td>
						<td scope="col">
							<b>{{ item.nome.toUpperCase() }}</b>
						</td>
						<td scope="col">
							{{ item.duracao / 5 }}
						</td>
						<td scope="col">
							{{ item.codigo_interno }}
						</td>
						<td scope="col">
							R$ {{ item.valor_base && String(item.valor_base).indexOf(',') > -1 ? String(item.valor_base) : ParseMoney(String(item.valor_base)) }}
						</td>
						<td scope="col">
							R$ {{ item.aliquot_value && String(item.aliquot_value).indexOf(',') > -1 ? String(item.aliquot_value) : ParseMoney(String(item.aliquot_value)) }}
						</td>
						<td scope="col">
							R$ {{ item.honorary_value && String(item.honorary_value).indexOf(',') > -1 ? String(item.honorary_value) : ParseMoney(String(item.honorary_value)) }}
						</td>
						<!-- <td scope="col">
							{{ item.duracao }}min
						</td> -->
					</template>
				</DataTables>

				<ProceedingForm
					:open="open_modal"
					:type="form_type"
					:proceeding="selected.proceeding"
					:module_id="selected.module_id"
					:modality_id="selected.modality_id"
					:parent_id="selected.subgroup_id"
					:is_vacancy="is_vacancy"
					@refresh="UpdateProceeding"
					@close="open_modal = false"
				/>

			</div>
		</transition>
	</div>
</template>

<script>
	import Modules from './components/Modules'
	import Modalities from './components/Modalities'
	import ProceedingGroup from './components/ProceedingGroup'
	import ProceedingSubgroup from './components/ProceedingSubgroup'
	import ProceedingForm from './components/ProceedingForm'

	import { mapGetters, mapActions } from 'vuex'
	import Swal from 'sweetalert2'

	const _ 		= require('lodash')
	export default {

		name: 'Proceedings',
		components: 	{
			Modules,
			Modalities,
			ProceedingGroup,
			ProceedingSubgroup,
			ProceedingForm
		},
		data () {
			return {
				step: 			'pre',
				partnerships: 	[],
				schedules: 		[],
				groups: 		[],
				subgroups: 		[],
				proceedings: 	[],
				headers:		[
					{ text: 'Status', sortable: true, value: 'status' },
					{ text: 'Nome', sortable: true, value: 'nome' },
					{ text: 'Vagas', sortable: true, value: 'vacancys' },
					{ text: 'Código', sortable: true, value: 'code' },
					{ text: 'Valor', sortable: true, value: 'value' },
					{ text: 'Alíquota', sortable: true, value: 'aliquot_value' },
					{ text: 'Honorário', sortable: true, value: 'honorary_value' },
	                // { text: 'Duração', sortable: true, value: 'duracao' },
				],
				selected: 	{
					module_id: 		null,
					modality_id:	null,
					group_id: 		null,
					subgroup_id:	null,
					proceeding_id: 	null
				},
				search: 		'',
				form_type: 		'new', //'config'
				open_modal: 	false,
				is_vacancy:		false
			}
		},
		computed: {
			...mapGetters('auth', [
				'requester_selected_id', 'requesters'
			]),
			...mapGetters('system', [
				'last_modality_id', 'modalities'
			]),
			module() {
				return this.modalities.filter((modality) => modality.id === this.last_modality_id)[0]
			},
			modality_name() {
				return window.helpers.Capitalize(this.modalities.find(modality => modality.id == this.selected.modality_id)?.name ?? '')
			}
		},
		watch: {
			step: {
				handler(val)
				{
					let hash 	= ''

					switch(val)
					{
						case 'module':
							hash = '#modulo'
							this.selected.modality_id		= null
							this.selected.group_id			= null
							this.selected.subgroup_id		= null
							this.selected.proceeding_id		= null
							this.groups 					= []
							this.subgroups 					= []
							this.proceedings 				= []
						break
						case 'modality':
							hash = '#modalidade'
							this.selected.group_id			= null
							this.selected.subgroup_id		= null
							this.selected.proceeding_id		= null
							this.groups 					= []
							this.subgroups 					= []
							this.proceedings 				= []
						break
						case 'group':
							hash = '#grupo'
							this.selected.subgroup_id		= null
							this.selected.proceeding_id		= null
							this.subgroups 					= []
							this.proceedings 				= []
						break
						case 'subgroup':
							hash = '#subgrupo'
							this.selected.proceeding_id		= null
							this.proceedings 				= []
						break
						case 'proceedings-list':
							hash = '#lista-de-procedimentos'
						break
					}

					window.location.hash 	= hash
				},
				immediate: true
			},
			last_modality_id: {
				async handler(val) {
					if(typeof val !== undefined && val !== null) {
						this.step					= 'modality'

						this.selected.module_id 	= this.module.id
						this.selected.modality_id 	= val
	
						await this.GetProceedings()
					}
				},
				immediate: true
			},
			$route(to)
			{
				switch(to.hash)
				{
					case '#modulo':
						this.step = 'module'
					break
					case '#modalidade':
						this.step = 'modality'
					break
					case '#grupo':
						this.step = 'group'
					break
					case '#subgrupo':
						this.step = 'subgroup'
					break
					case '#lista-de-procedimentos':
						this.step = 'proceedings-list'
					break
				}
			}
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading', 'GetModulesAndModalities', 'SetLastModalityId'
			]),
			async WalkStep(param = null)
			{
				switch(this.step)
				{
					case 'pre':

						this.step 						= 'module'

					break
					case 'module':
						
						this.selected.module_id 		= param.id

						this.step 						= 'modality'

					break
					case 'modality':
						
						this.selected.modality_id		= param.id

						await this.GetProceedings()

					break
					case 'group':
						
						this.selected.group_id 		= param.id
						this.subgroups 					= param.children ?? []

						this.step 						= 'subgroup'

					break
					case 'subgroup':
						
						this.selected.subgroup_id	 	= param.id
						this.proceedings 				= param.children ?? []

						this.step 						= 'proceedings-list'

					break
					case 'proceedings-list':

					break
				}
			},
			GetProceedings()
			{
				this.StartLoading()

				return window.api.call('post', '/get-proceedings-by-account', {
						account_id: 	this.requester_selected_id,
						modality_id: 	this.selected.modality_id
					})
					.then(({data}) => {
						if(data.response)
						{
							this.is_vacancy		= data.is_vacancy
							this.groups 		= data.groups
							this.step 			= 'group'
						}

						this.SetLastModalityId(null)
					})
					.finally(() => {
						this.FinishLoading()
					})
			},
			UpdateProceeding(proceeding)
			{
				let just_edit = false
				
				this.proceedings 	= this.proceedings.map(p => {
					let new_p = p

					if(parseInt(p.id) == parseInt(proceeding.id)) {
						new_p = proceeding
						just_edit = true
					}

					return new_p
				})

				if(!just_edit) {
					this.proceedings.push(proceeding)
					this.proceedings = _.orderBy(this.proceedings, 'nome', 'asc')
				}
			},
			UpdateGroups(groups)
			{
				this.groups 	= groups
			},
			UpdateSubgroups(subgroups)
			{
				this.subgroups 	= subgroups
			},
			New()
			{	
				this.form_type 					= 'new'
				this.selected.proceeding 		= null
				this.open_modal 				= true
			},
			Config(proceeding)
			{
				this.form_type 					= 'config'
				this.selected.proceeding 		= proceeding
				this.open_modal 				= true
			},
			ToggleProceedingStatus(proceeding)
			{
				const vm = this

				vm.StartLoading()

				const steps_selected = {
					group_id: vm.selected.group_id,
					subgroup_id: vm.selected.subgroup_id
				}

				return window.api.call('post', '/toggle-proceeding-status', {
					account_id: vm.requester_selected_id,
					proceeding_id: proceeding.id
				})
					.then(async ({data}) => {
						if(data.response) {
							Swal.fire({
								toast: true,
								position: 'top',
								timerProgressBar: true,
								timer: 3000,
								title: data.message,
								icon: 'success',
								showConfirmButton: false
							})

							const new_proceeding = proceeding

							new_proceeding.status = !new_proceeding.status

							vm.UpdateProceeding(new_proceeding)

							return
						}

						Swal.fire({
							toast: true,
							position: 'top',
							title: data.message,
							icon: 'error'
						})
					})
					.finally(() => {
						vm.FinishLoading()
					})
			},
			ParseMoney(value) {
				const numValue = parseFloat(value)

				return numValue.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')
            }
		},
		async beforeMount()
		{
			await this.GetModulesAndModalities()

			this.WalkStep()
		}
	}
</script>

<style lang="css" scoped>
</style>