<template>
	<div>
		<p>Autenticando...</p>
	</div>
</template>

<script>
	import Swal from 'sweetalert2'
	import { mapActions, mapGetters } from 'vuex'
	export default {

		name: 'Authenticate',
		props: 	{
			token: 	{
				type: 	String,
				default: null
			}
		},
		data () {
			return {

			}
		},
		computed: {
			...mapGetters('auth', [
				'check'
			])
		},
		methods: {
			...mapActions('auth', [
				'GetAuthBridgeData', 'LogoutContent'
			]),
			GoBack()
			{
				// window.location.href 	= process.env.VUE_APP_LANDING_PAGE_URL
			}
		},
		async beforeMount()
		{
			await this.LogoutContent()
		},
		async mounted()
		{
			if(!this.token)
			{
				this.GoBack()
			}

			return await this.GetAuthBridgeData(this.token)
				.then(async (data) => {
					window.Echo.RunPrivate()
					window.Echo.RunGlobal()
					
					this.$router.push({ name: 'Home' })
				})
				.catch((response) => {
					this.GoBack()
				})
		}
	}
</script>

<style lang="css" scoped>
</style>