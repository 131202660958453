<template>
	<div>
		<p class="text-center">Aqui você pode importar e exportar os procedimentos da organização.</p>
		<div class="border px-4 py-3 mt-3 rounded-lg">
			<div class="col-12 d-flex my-2">
				<!-- Area de exportacao -->
				<div class="col mr-2 card bg-light rounded-lg border-no overflow-hidden">
					<p class="my-2 text-center font-weight-bold text-dark font-20">Exportações</p>
					<div class="select-card with-border my-2 py-1 px-2 mr-1"
						@click="OpenModal()">
						<label class="">
							<div class="col rounded-lg d-flex justify-content-center">
								<p class="mb-0">Nova exportação</p>
							</div>
						</label>
					</div>
					<div class="mh-138">
						<div class="mh-200 text-muted d-flex justify-content-center align-items-center"
							v-if="GetProceedingsMovements('e') == 0">
							<span>Não há nenhum arquivo aqui</span>
						</div>
						<div v-for="(proceedings_movement) in GetProceedingsMovements('e')"
							:key="proceedings_movement.id">
							<div class="border rounded-lg my-2 py-1 px-2 mr-1">
								<div class="col d-flex justify-content-between">
									<span>Exportação #{{ proceedings_movement.id }}</span>
									<span>{{ ParseDate(proceedings_movement.created_at) }}</span>
									<a class="px-1"
										v-tippy="{ arrow: true }"
										content="Fazer download"
										@click="DownloadFile(proceedings_movement.url)"
										v-if="proceedings_movement.url && !proceedings_movement.in_progress"
									>
										<i class="fa fa-download"></i>
									</a>
									<span
										v-tippy="{ arrow: true }"
										content="Arquivo na fila de processamento"
										v-else-if="proceedings_movement.status == 'queued'" 
									>
										<i class="fas fa-clock    "></i>
									</span>
									<span
										v-tippy="{ arrow: true }"
										:content="'Gerando arquivo: ' + (proceedings_movement.progress_percentage > 100 ? 99 : proceedings_movement.progress_percentage) + '%'"
										v-else-if="proceedings_movement.status == 'executing'" 
									>
										<span class="spinner-border spinner-border-sm mt-2" role="status" aria-hidden="true"></span>  
									</span>
									<span
										v-tippy="{ arrow: true }"
										content="Houve uma falhar ao gerar o arquivo..."
										v-else-if="proceedings_movement.status == 'failed' || proceedings_movement.status == 'retrying' || ( proceedings_movement.status == 'finished' && !proceedings_movement.url )" 
									>
										<i class="fas fa-exclamation-circle text-danger"></i>
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- Area de importacao -->
				<div class="col ml-2 card bg-light rounded-lg border-no overflow-hidden">
					<p class="my-2 text-center font-weight-bold text-dark font-20">Importações</p>
					<div class="select-card with-border my-2 py-1 px-2 mr-1"
						@click="ImportProceedings()"
						:disabled="HasPendingProgress()"
					>
						<label class="">
							<div class="col rounded-lg d-flex justify-content-center">
								<p class="mb-0">Nova importação</p>
							</div>
						</label>
					</div>
					<div class="mh-138">
						<div class="mh-200 text-muted d-flex justify-content-center align-items-center"
							v-if="GetProceedingsMovements('i') == 0"
						>
							<span>Não há nenhum arquivo aqui</span>
						</div>
						<div v-for="(proceedings_movement) in GetProceedingsMovements('i')"
							:key="proceedings_movement.id">
							<div class="border rounded-lg my-2 py-1 px-2">
								<div class="col d-flex justify-content-between">
									<span>Importação #{{ proceedings_movement.id }}</span>
									<span>{{ ParseDate(proceedings_movement.created_at) }}</span>
									<a class="px-1"
										v-tippy="{ arrow: true }"
										content="Fazer download"
										@click="DownloadFile(proceedings_movement.url)"
										v-if="proceedings_movement.url && !proceedings_movement.in_progress"
									>
										<i class="fa fa-download"></i>
									</a>
									<span
										v-tippy="{ arrow: true }"
										content="Arquivo na fila de processamento"
										v-else-if="proceedings_movement.status == 'queued'" 
									>
										<i class="fas fa-clock    "></i>
									</span>
									<span
										v-tippy="{ arrow: true }"
										:content="'Importando os dados: ' + proceedings_movement.progress_percentage + '%'"
										v-else-if="proceedings_movement.status == 'executing'" 
									>
										<span class="spinner-border spinner-border-sm mt-2" role="status" aria-hidden="true"></span>  
									</span>
									<span
										v-tippy="{ arrow: true }"
										:content="GetProceedingsMovementsFailMessage(proceedings_movement)"
										v-else-if="proceedings_movement.status == 'failed' || proceedings_movement.status == 'retrying' || (proceedings_movement.status == 'finished' && !proceedings_movement.url)" 
									>
										<i class="fas fa-exclamation-circle text-danger"></i>
									</span>
								</div>
							</div>
						</div>
					</div>
					<input type="file" name="" :id="'input-proceedings' "
						accept=".xlsx"
						hidden
						@change="ImportProceedings($event)">
				</div>
			</div>
		</div>
		
		<div class="modal fade" id="modal-export-proceedings">
			<div class="col modal-dialog d-flex justify-content-center align-items-center"
				@keydown.esc="CloseModal">
				<div class="modal-content p-3 d-flex">
					<div class="align-self-end">
						<button
							id="close-modal"
							class="btn btn-light btn-sm m-2"
							@click="CloseModal"
						>
							<i class="fal fa-times"></i>
						</button>
					</div>
					<div class="modal-header mb-4">
						<h4 class="font-20 m-0 text-center">
							Exportar Procedimentos
						</h4>
					</div>
					<div class="modal-body d-flex flex-column align-items-center justify-content-start">
						<div id="modules_and_modalities-dropdown" class="dropdown mb-2 mb-md-0 w-100 px-3">
							<div class="pr-2 overflow-auto" style="max-height: 60vh;">
								<div class="d-flex flex-wrap flex-column modules-container">
									<div class="form-check form-check-inline">
										<input
											id="modules_and_modalities_select_all"
											class="form-check-input"
											type="checkbox"
											v-model="filters.modules_and_modalities.select_all"
											hidden
										>
										<label 
											class="form-check-label w-100"
											for="modules_and_modalities_select_all"
										>
											SELECIONAR TUDO
										</label>
									</div>

									<hr class="my-3">

									<ul
										v-for="module in parsed_modules"
										:key="module.id"
									>
										<li class="mb-3 list-unstyled">
											<div class="d-flex justify-content-between position-relative mb-2">
												<div class="form-check form-check-inline">
													<input
														class="form-check-input"
														type="checkbox"
														:id="'ChkModule_' + module.id"
														:name="'ChkModule_' + module.id"
														:value="!!module.id"
														v-model="filters.modules_and_modalities[`module_${module.id}`].selected"
														@change="ModuleChangeHandler($event, module.id)"
														hidden
													>
													<label class="form-check-label" :for="'ChkModule_' + module.id">
														{{ module.name }}
													</label>
												</div>
												<div class="chevron-expand-container"
													@click="(e) => { 
														filters.modules_and_modalities[`module_${module.id}`].expanded = !filters.modules_and_modalities[`module_${module.id}`].expanded;
													}">
													<i class="chevron-expand fa fal"
														:class="{
															'fa-chevron-right': !filters.modules_and_modalities[`module_${module.id}`].expanded,
															'fa-chevron-down': filters.modules_and_modalities[`module_${module.id}`].expanded
														}">
													</i>
												</div>
											</div>

											<div class="mb-3 collapse"
												:id="'collapse_' + module.id"
												:class="{
													'show': filters.modules_and_modalities[`module_${module.id}`].expanded
												}"
											>
												<ul v-for="modality in module.modalities"
													:key='modality.id'
												>
													<li class="list-unstyled ml-4 mb-2">
														<div class="form-check form-check-inline">
															<input
																class="form-check-input"
																type="checkbox"
																:id="'ChkModality_' + modality.id"
																:name="'ChkModality_' + modality.id"
																:value="modality.id"
																v-model="filters.modules_and_modalities[`module_${module.id}`].modalities"
																@change="ModalityChangeHandler($event, modality.id)"
																hidden
															>
															<label class="col pr-0 form-check-label" :for="'ChkModality_' + modality.id">
																{{ modality.name }}
															</label>
														</div>
													</li>
												</ul>
											</div>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					
					<div class="modal-footer p-1">
						<button 
							id="btn-export-proceedings"
							class="btn align-self-end mt-2 mb-0 mx-3 btn-primary"
							@click="ExportProceedings()">
							Exportar
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import _, { split } from 'lodash'
	import Helpers from '../../../helpers'
	import moment from 'moment'
	import axios from 'axios'
	const helpers 		= new Helpers()

	import Swal from 'sweetalert2'
	import { mapGetters, mapActions } from 'vuex'
	export default {
		name: 'Proceedings',
		props: {
			providers: 			{
				type: 				Array,
				default: 			() => []
			},
			organization: 		{
				type: 				Object,
				default: 			() => {}
			},
			permission:			{
				type:				Boolean,
				default:			false
			},
			can_modify:			{
				type:				Boolean,
				default:			false
			}
		},
		data () {
			return {
				search: '',
				selected:			{
					proceeding: null
				},
				filters: {
                    modules_and_modalities: {
                        "module_1": { id: 1, selected: false, expanded: false, modalities: [] },
                        "module_2": { id: 2, selected: false, expanded: false, modalities: [] },
                        "module_3": { id: 3, selected: false, expanded: false, modalities: [] },
                        "module_4": { id: 4, selected: false, expanded: false, modalities: [] },
                        "module_5": { id: 5, selected: false, expanded: false, modalities: [] },
                        "module_6": { id: 6, selected: false, expanded: false, modalities: [] },
                        "module_7": { id: 7, selected: false, expanded: false, modalities: [] },
                        "module_8": { id: 8, selected: false, expanded: false, modalities: [] },
                        "module_9": { id: 9, selected: false, expanded: false, modalities: [] },
                        "module_10": { id: 10, selected: false, expanded: false, modalities: [] },
                        "select_all": false,
                    },
                    modalities: [],
                },
				input_clickable: true,
			}
		},
		computed: {
			...mapGetters('auth', [
				'account', 'requester_selected_id', 'update_current_url'
			]),
			...mapGetters('system', [
                'modules', 'modalities'
            ]),
            lib_schema() {
                return this.organization.instances[0].config.schema_name
            },
			parsed_modules() {
                return this.modules.map(module => {
					return {
						...module,
						modalities: this.modalities.filter(modality => modality.module_id == module.id)
					}
				})
            }
		},
		watch: {
			update_current_url: {
				handler(val) {
					if(val) {
 						this.$parent.GetOrganizationData(false)

						this.UpdateCurrentUrl(false);

                        this.$nextTick();
					}
				}
			},
			'filters.modules_and_modalities.select_all': {
                handler(val) {
                    const modules_and_modalities = JSON.parse(JSON.stringify(this.filters.modules_and_modalities))

                    for(const [index, module] of Object.entries(modules_and_modalities)) {
                        if(index != 'select_all') {
                            this.filters.modules_and_modalities[index].selected = val

                            if(val) {
                                this.filters.modules_and_modalities[index].modalities = this.modalities.filter(m => m.module_id == module.id)
                                    .map(m => m.id)
                            } else {
                                this.filters.modules_and_modalities[index].modalities = []
                            }
                        }
                    }
                }
            }
		},
		methods: {
			...mapActions('system', [
				'StartLoading', 'FinishLoading', 'GetModulesAndModalities'
			]),	
			...mapActions('auth', [
				'UpdateCurrentUrl'
			]),
			ModuleChangeHandler(event, module_id) {
                if(!event.target.checked) {
                    this.filters.modules_and_modalities[`module_${module_id}`].modalities = []
                } else {
                    if(!this.filters.modules_and_modalities[`module_${module_id}`].modalities.length) {
                        this.filters.modules_and_modalities[`module_${module_id}`].modalities = this.modalities.filter(m => m.module_id == module_id)
                            .map(m => m.id)
                    }
                }
            },
            ModalityChangeHandler(event, modality_id) {
                const modality = this.modalities.find(m => m.id == modality_id)
                    if(!this.filters.modules_and_modalities[`module_${modality.module_id}`].selected) {
                        this.filters.modules_and_modalities[`module_${modality.module_id}`].selected = true
                    }
            },
			GetProceedingsMovements(type) {
				return this.organization.proceedings_movements.filter( e => e.type == type )
			},
			ParseDate(date) {
				return moment(date).format('DD/MM/YYYY HH:mm');
			},
			HasPendingProgress() {
				let pendings = this.organization.proceedings_movements.filter( e => e.status == 'queued' || e.status == 'executing' )

				return pendings.length > 0
			},
			DownloadFile(url) {
				const method = 'GET';

				this.StartLoading()

				axios.request({
					url,
					method,
					responseType: 'blob',
				})
				.then(({ data }) => {
					const downloadUrl = window.URL.createObjectURL(new Blob([data], {type : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
					const link = document.createElement('a');
					link.href = downloadUrl;
					link.setAttribute('download', '')
					document.body.appendChild(link);
					link.click();
					link.remove();
				})				
				.finally(() => {
					this.FinishLoading()
				})
			},
			ExportProceedings() {
				if(this.HasPendingProgress()) {
					return Swal.fire({
						icon: 'info',
						toast: true,
						timer: 5000,
						position: 'top',
						html: 'Um procedimento já em andamento, aguarde.'
					})
				}

				this.StartLoading()

				const modules_and_modalities = JSON.parse(JSON.stringify(this.filters.modules_and_modalities))

                this.filters.modalities = []
                
                for(const [index, module] of Object.entries(modules_and_modalities)) {
                    if(index != 'select_all') {
                        if(module.selected && !module.modalities.length) {
                            module.modalities = this.modalities.filter(m => m.module_id == module.id)
                                .map(m => m.id)
                        }

                        module.modalities.forEach(modality => {
                            this.filters.modalities.push(modality)
                        })
                    }
                }

				return window.api.call('post', '/proceedings-movement/export/proceedings', {
					account_id: this.account.id,
					organization_id: this.organization.id,
					modalities: this.filters.modalities || []
				})
				.then(({data}) => {
					if(!data.response) {
						Swal.fire({
							icon: 'error',
							text: data.message,
							title: 'Ops!'
						});
					} else {
						Swal.fire({
							icon: 'info',
							title: 'Em andamento...',
							toast: true,
							position: 'top',
							html: `${data.message}`,
						});
					}

					this.CloseModal()
					this.$parent.GetOrganizationData()
				})
				.finally(() => {
					this.FinishLoading()
				})
			},
			ImportProceedings(event)
			{
				if(this.HasPendingProgress()) {
					return Swal.fire({
						icon: 'info',
						toast: true,
						timer: 5000,
						position: 'top',
						html: 'Um procedimento já em andamento, aguarde.'
					});
				}

				if(event && event.target.files.length > 0) {
					const file = event.target.files[0];

					this.StartLoading();

					const formData = new FormData();

					formData.append('file', file, file.name);
					formData.append('account_id', this.account.id);
					formData.append('organization_id', this.organization.id);

					return window.api.call('post', '/proceedings-movement/import/proceedings', formData)
                        .then(({data}) => {
                            if(!data.response) {
                                Swal.fire({
                                    icon: 'error',
                                    text: data.message,
                                    title: 'Ops!'
                                });
                            } else {
                                Swal.fire({
                                    icon: 'info',
                                    title: 'Ops!',
                                    text: `A importação ${data.proceedings_movement.id} foi para a fila de processamento.`,
                                });

                                this.$parent.GetOrganizationData();
                            }
								
							document.querySelector('#input-proceedings' ).value = null;
                        })
                        .finally(() => {
                            this.FinishLoading();
                        })
				}

                if(!this.input_clickable) return
                
				document.querySelector('#input-proceedings'  ).click();
			},
			GetProceedingsMovementsFailMessage(proceedings_movement) {
				if (proceedings_movement?.job_status?.output?.message) { 
					return proceedings_movement.job_status.output.message.substring(0, 100) + '...';
				}

				return 'Não foi possível importar.';
			},
			OpenModal() {
				window.jQuery('#modal-export-proceedings').modal('show');

				window.jQuery('body').addClass('modal-open');

				window.jQuery('#modal-export-proceedings').addClass('d-flex');
			},
			CloseModal() {
				window.jQuery('#modal-export-proceedings').modal('hide')
					.on('hidden.bs.modal', () => {
						window.jQuery('body').removeClass('modal-open');
						
						window.jQuery('#modal-export-proceedings').removeClass('d-flex');
					});
			},
		},
		async beforeMount() {
			await this.GetModulesAndModalities()
		}
	}
</script>

<style lang="css" scoped>

.chevron-expand-container {
	position: absolute;
	right: 0;
	top: 0;
	width: 92%;
	height: 24.02px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: end;
}

.mh-138
{
	height: 138px;
	overflow: auto;
}

.fa-download {
	cursor: pointer;
}

.select-card label {
	padding-right: 1.5rem !important;
}

</style>
